import $ from 'jquery'
window.$ = $
window.jQuery = $
import {} from 'bootstrap'
import {} from 'jquery-validation'

import Rails from '@rails/ujs'

// import Turbolinks from 'turbolinks' // eslint-disable-line
// Turbolinks.start()

$(document).ready(function () {
  // mobile menu
  $('.menu-icon').on('click', function() {
    $('body').toggleClass('open-menu');
  });

  $('#new_user').validate({
    rules: {
      'user[email]': {
        required: true,
      },
      'user[password]': {
        required: true,
      },
    },
    messages: {
      'user[email]': {
        required: 'Please enter your email.',
      },
      'user[password]': {
        required: 'Please enter your password.',
      },
    },
    errorElement: 'div',
    errorPlacement: function (error, element) {
      error.addClass('invalid-feedback d-block')
      $(element).closest('.input-group').append(error)
      $(element).closest('.input-group').children('.error-placeholder').remove()
    },
    highlight: function (element) {
      $(element).addClass('is-invalid').removeClass('is-valid')
    },
    unhighlight: function (element) {
      $(element).addClass('is-valid').removeClass('is-invalid')
    },
  })
})

$('form').on('change keyup keypress blur', 'input[name=\'create_team[people][]\']:last-of-type', function () {
  if ($('input[name=\'create_team[people][]\']:last-of-type').last().val() !== '') {
    $('#email_list').append($('input[name=\'create_team[people][]\']:last-of-type').last().clone().val(''))
  }
})
